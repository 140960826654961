import LoadingButton from '@mui/lab/LoadingButton';
import {
	Stack,
	TextField,
	Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useGenerateOtp } from '../../utils/login.utils';


interface PhoneProps {
	handleOnSubmit: (phone: string) => void;
};

export const Phone = (props: PhoneProps) => {
	const [loading, requestOtp ] = useGenerateOtp();
	
	const formik = useFormik({
		initialValues: {
			phone: '',
			submit: null
		},
		validationSchema: yup.object({
			phone: yup
				.string()
				.min(10, 'Phone number is too short')
				.max(10, 'Phone number is too long')
				.required('Phone number is required'),
		}),
		onSubmit: async (values, helpers) => {
			const onSubmit = () => {
				props.handleOnSubmit(values.phone);
			};
			await requestOtp(values.phone, onSubmit);
		}
	});
	return (
		<form
			noValidate
			onSubmit={formik.handleSubmit}
		>
			<Stack spacing={3}>
				<TextField
					error={!!(formik.touched.phone && formik.errors.phone)}
					fullWidth
					helperText={formik.touched.phone && formik.errors.phone}
					label="Phone Number"
					name="phone"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="phone"
					value={formik.values.phone}
				/>
			</Stack>
			{formik.errors.submit && (
				<Typography
					color="error"
					sx={{ mt: 3 }}
					variant="body2"
				>
					{formik.errors.submit}
				</Typography>
			)}
			<LoadingButton
				fullWidth
				size="large"
				sx={{ mt: 3 }}
				type="submit"
				variant="contained"
				loading={loading}
			>
				Generate OTP
			</LoadingButton>
		</form>
	)
}
