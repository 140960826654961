import {
	Box,
	Divider,
	MenuItem,
	MenuList,
	Popover,
	Typography,
	ListItemButton,
	ListItemIcon,
	SvgIcon,
	ListItemText
} from "@mui/material";
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { useAuthContext } from "../contexts/auth-context";
import { useNavigate } from "react-router-dom";
import { useCallback, useRef, useState } from "react";
import { ShopSelector } from "./login/shop.selector";

export const AccountPopover = (props: any) => {
	const { anchorEl, onClose, open } = props;
	const { jwtDecoded, signOut } = useAuthContext();
	const switchShopRef = useRef(null);
	const [openSwitchShop, setopenSwitchShop] = useState(false);

	const resetShop = () => {
		setopenSwitchShop(!openSwitchShop)
	}

	const handleSwitchShopClose: any = useCallback(() => {
		setopenSwitchShop(false);
	}, []);


	return (
		<>
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					horizontal: "left",
					vertical: "bottom",
				}}
				onClose={onClose}
				open={open}
				PaperProps={{ 
					sx: {  width: 200, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", border: "1px solid #646bee" }
				}}
			>
				<Box
					sx={{
						py: 1.5,
						px: 2,
					}}
				>
					<Typography variant="overline">Account</Typography>
					<Typography color="text.secondary" variant="body2">
						{jwtDecoded?.email}
					</Typography>
				</Box>
				<Divider />
				<Box sx={{ p: 1 }}>
					
					<ListItemButton
						onClick={resetShop}
						sx={{
							borderRadius: 1,
							px: 1,
							py: 0.5,
						}}
						ref={switchShopRef}
					>
						<ListItemIcon>
							<SvgIcon fontSize="small">
								<SwitchAccessShortcutIcon />
							</SvgIcon>
						</ListItemIcon>
						<ListItemText
							primary={<Typography variant="body1">Switch Shop</Typography>}
						/>
					</ListItemButton>
				</Box>
				<Divider />
				<MenuList
					disablePadding
					dense
					sx={{
						p: "8px",
						"& > *": {
							borderRadius: 1,
						},
					}}
				>
					<MenuItem onClick={signOut}>Sign out</MenuItem>
				</MenuList>
			</Popover>

			<Popover
				anchorEl={switchShopRef.current}
				anchorOrigin={{
					horizontal: "left",
					vertical: "bottom",
				}}
				onClose={handleSwitchShopClose}
				open={openSwitchShop}
				PaperProps={{
					 sx: {  width: 300, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", border: "1px solid #646bee" }
				}}
				elevation={10}
			>
				<Box sx={{ p: 2 }}>
					<ShopSelector />
				</Box>
				
			</Popover>
		</>
	);
};
