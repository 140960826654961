import { useState } from 'react';
import { useMutation, gql, ApolloError } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../contexts/auth-context';
import { Typography } from '@mui/material';
import { ResendOtp } from './resend-otp';
import { OTP_LENGTH } from '../../constants';

interface OtpProps {
	handleOnSubmit: () => void
	phone: string;
}

const AUTHENTICATE_WITH_OTP = gql`
mutation jwt($phone: String!, $otp: String!) {
  login(phone: $phone, otp: $otp)
}
`;

export const Otp = (props: OtpProps) => {
	const { setJwt } = useAuthContext();
	const [doLogin, { loading }] = useMutation(AUTHENTICATE_WITH_OTP);
	const [otp, setOtp] = useState('');

	const handleChange = (newValue: any) => {
		setOtp(newValue);
	};

	const handleOnClick = async () => {
		try {
			const { data: { login } } = await doLogin({ variables: { phone: props.phone, otp } });
			setJwt(login);
			props.handleOnSubmit();
		} catch (e) {
			toast.error((e as ApolloError).message);
		}
	};

	const resetOtp  = () => {
		setOtp('');
	}

	return (
		<>
			<Typography sx={{ mb: 2,textAlign: "center" }} color="text.secondary" variant="body2" >
				Enter the OTP send to:  <strong>{props.phone}</strong>
			</Typography>		
			<MuiOtpInput value={otp} onChange={handleChange} />
			<LoadingButton
				fullWidth
				size="large"
				sx={{ mt: 3 }}
				type="submit"
				variant="contained"
				onClick={handleOnClick}
				loading={loading}
				disabled={otp.length == OTP_LENGTH ? false : true} 
			> 
				Login
			</LoadingButton>
			<ResendOtp phone={props.phone} resetOtp={resetOtp}/> 
				
		</>
	)
}
