import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Typography } from '@mui/material';
import { RESEND_OTP_IN_SEC } from '../../constants';
import { useGenerateOtp } from '../../utils/login.utils';

interface ResendOtpProps {
	phone: string;
	resetOtp: () => void
}

export const ResendOtp = (props: ResendOtpProps) => {
	const [timer, setTimer] = useState(RESEND_OTP_IN_SEC);
	const [resendOtpDisabled, setresendOtpDisabled] = useState(true);
	const [_, requestOtp]  = useGenerateOtp();
	
	useEffect(() => {
		if (timer > 0) {
			const interval = setInterval(() => {
				setTimer((prev) => prev - 1);
			}, 1000);

			return () => clearInterval(interval); 
		} else {
			setresendOtpDisabled(false);
		}

	}, [timer]);

	const handleResendOtp = () => {
		setTimer(RESEND_OTP_IN_SEC);
		setresendOtpDisabled(true);
		props.resetOtp();
		regenerateOtp();
		
	};

	const regenerateOtp = async () => {
		const onSuccessToast = () => {
			toast.success("OTP has been sent successfully"); 
		};
		await requestOtp(props.phone, onSuccessToast);
	};

	return (
		<>
			<Box 
				sx={{
					 display: 'flex',
					 justifyContent: 'center',
					 alignItems: 'center',
					 gap: 1,
					 mt :4
				}}
			>
				{ resendOtpDisabled  ? (
					<Typography
						color="text.secondary"
						variant='body2'
					>
						Resend OTP in
						<Box
							component="span"
							sx={{
								fontWeight: 'bold',
								color:'primary.main',
								mr: 1,
								ml:1
							}} 
						>
							{timer}
						</Box >
						seconds:
					</Typography>
				): (
					<Typography
						color="text.secondary"
						variant='body2'
					>
						Did not get the OTP? 
					</Typography>
				)}
				<Button
					variant='text' 
					sx={{ml:2, textDecoration: 'underline', padding: 0, textTransform: "none"}}
					disabled={resendOtpDisabled}
					onClick={handleResendOtp}
				>
					Resend OTP
				</Button>
			</Box>
			
		</>
	)
}



