import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

interface AlertDialogSlideProps {
	open: boolean;
	description: string;
	onDisagree: () => void;
	onAgree: () => void;
	confirmText?: string;
	cancelText?: string;
}

const AlertDialogSlide = (props: AlertDialogSlideProps) => {
	const { description, onDisagree, onAgree, confirmText, cancelText } = props;
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		setOpen(props.open);
	}, [props.open])

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onDisagree}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle>{description}</DialogTitle>
				<DialogActions>
					<Button onClick={onDisagree}>{cancelText}</Button>
					<Button onClick={onAgree}>{confirmText}</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

AlertDialogSlide.defaultProps = {
	confirmText: 'Agree',
	cancelText: 'Disagree'
};

export { AlertDialogSlide };