import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as Sentry from "@sentry/react";
import * as yup from "yup";
import {
	Customer,
	OrderInput,
	OrderProductInput,
	OrderServiceInput,
	User,
} from "../../gql/graphql";
import UserList from "../../pages/dashboard/user-list.page";
import { useMutation, useLazyQuery } from "@apollo/client";

import {
	Box,
	Card,
	CardContent,
	Paper,
	TextField,
	Unstable_Grid2 as Grid,
} from "@mui/material";

import { toast } from "react-toastify";
import { GenericModalV2 } from "./generic.modal";
import { OrderTable } from "../order/order.table";
import { useShopDetails } from "../../hooks/use-shop";
import { useOrderContext } from "../../contexts/order-context";
import { ADD_UPDATE_ORDER, GET_ORDER_BY_ID } from "../../gql/queries";
import EditCustomer from "./edit.customer";
import { useAuthContext } from "../../contexts/auth-context";
import { SelectComponent } from "../select";
import { printPOSReceipt } from "../../utils/electron";
import { setUpPrinter } from "../../utils/order.utils";
import { AlertDialogSlide } from "./generic.confirm";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="right" ref={ref} {...props} />;
});

interface PrinterData {
	data: any;
	order: OrderInput;
  }

const validationSchema = yup.object({
	customerId: yup.string().required("Customer is required"),
});


export default function EditOrderComponent(props: any) {
	const { data: orderSelected } = props;
	const { shopId, locationId } = useShopDetails();
	const { shopDetails } = useAuthContext();
	const [ printDialog, setPrintDialog ] = useState(false);
	const [ printerData, setPrinterData ] = useState<PrinterData | null> (null);
	const [selectedModal, setSelectedModal] = useState<
		"user" | "customer" | null
	>();

	const [addUpdateOrder, { loading }] = useMutation(ADD_UPDATE_ORDER);
	const orderContext = useOrderContext();
	const [getOrderByOrderId] = useLazyQuery<Record<"order", OrderInput>>(
		GET_ORDER_BY_ID,
		{}
	);

	const Modal = useMemo(() => {
		switch (selectedModal) {
			case "user":
				return () => (
					<GenericModalV2 open={true} title={""}>
						<UserList onClose={handleClose} isModal={true} />
					</GenericModalV2>
				);
			case "customer":
				return () => (
					<EditCustomer onClose={handleClose} isPhoneSearchable={true} />
				);
			default:
				return null;
		}
		// eslint-disable-next-line
	}, [selectedModal]);

	useEffect(() => {
		orderContext.resetOrder();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (orderSelected?._id) {
			const setInitialValues = async () => {
				const { data } = await getOrderByOrderId({
					variables: { id: orderSelected?._id },
				});
				if (data?.order) {
					formik.setFieldValue("customerId", data.order?.customerId);
					formik.setFieldValue("customerName", data.order?.customerName);
					formik.setFieldValue("paymentMethod", data.order?.paymentMethod);

					if (data.order?.products) {
						data.order.products.forEach((product: OrderProductInput) => {
							orderContext.addProduct(product);
						});
					}
					if (data.order?.services) {
						data.order.services.forEach((service: OrderServiceInput) => {
							orderContext.addService(service);
						});
					}

					formik.setValues({
						...formik.values,
						...data.order,
					});
				}
			};
			setInitialValues();
		}
		// eslint-disable-next-line
	}, []);

	const formik = useFormik<Partial<OrderInput>>({
		initialValues: {
			locationId,
			shopId,
			customerId: "",
			paymentMethod: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				const orderResult = await handleAddUpdateOrder(values);
				if (orderResult) {
					const { data, order } = orderResult;
					const { sequenceNumber } = data?.addUpdateOrder;
					toast.success(
						`Order: ${sequenceNumber} ${sequenceNumber ? "updated" : "created"
						} successfully.`
					);
					setPrintDialog(true);
					setPrinterData({ data, order: order as OrderInput });
				} else {
					toast.error("Something went wrong, please try again.");
				}
			} catch (e: any) {
				toast.error(e.message);
			}
		},
	});

	const handleAddUpdateOrder = async (values: any) : Promise<{ data: any; order: Partial<OrderInput>} | undefined> =>{
		const order: OrderInput = {
			...orderContext.order,
			...values,
			products: [...orderContext.order.products],
			services: [...orderContext.order.services],
		};

		if (!order.products.length && !order.services.length) {
			throw new Error("Please add products or services to the order.");
		};

		if (
			(order.products.length && !order.products.every(item => item.stylistUserId)) ||
			(order.services.length && !order.services.every(item => item.stylistUserId))
		) {
			throw new Error("Stylist is not selected in one of the order/service.");
		}
		
		const { data } = await addUpdateOrder({
			variables: {
				order,
			},
		});
		const { _id } = data.addUpdateOrder;
		formik.setValues({
			...formik.values,
			_id,
		});
		return { data, order };
	}

	const handlePrint = async () => {
		setPrintDialog(false);
		if (printerData) {
			const { data, order } = printerData;
			await setUpPrinter(shopId, locationId);
			try {
				if (window?.electronAPI?.isDesktop) {
					const { sequenceNumber, createdAt } = data.addUpdateOrder;
					await printPOSReceipt({ ...order, createdAt, sequenceNumber }, shopDetails!);
					Sentry.captureMessage("Printing receipt successful");
				}
			} catch (e) {
				Sentry.captureException(e);
				toast.error("Error printing receipt.");
				toast.warn("Please check your printer settings Settings > General > Printer Settings");
			}
			props.onClose();
		} else {
			toast.error("Error printing receipt.");
		}
	}

	const handleClose = React.useCallback(
		(data: Customer | User) => {
			switch (selectedModal) {
				case "customer": {
					if (data?._id) {
						formik.setFieldValue("customerId", data._id);
						formik.setFieldValue(
							"customerName",
							`${data.firstName} ${data.lastName}`
						);
					}
					break;
				}
			}
			setSelectedModal(null);
		},
		// eslint-disable-next-line
		[selectedModal]
	);

	return (
		<Dialog fullScreen open={true} TransitionComponent={Transition}>
			{Modal && <Modal />}
			{ printDialog && <AlertDialogSlide 
				open={printDialog}
				onAgree={handlePrint}
				onDisagree={()=>setPrintDialog(false)}
				description='Are you sure want to print?'
				confirmText='Confirm'
				cancelText='Cancel'/> 
			}

			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<AppBar sx={{ position: "relative" }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							// onClick={handleClose}
							aria-label="close"
							onClick={props.onClose}
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Billing
						</Typography>
						<LoadingButton autoFocus color="inherit" onClick={formik.submitForm} loading={loading}>
							Save & Print
						</LoadingButton>
					</Toolbar>
				</AppBar>
				<Card>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: 5 }}>
							<Grid container>

								<Grid xs={12} md={6}>
									<Paper
										component="form"
										sx={{
											p: "2px 4px",
											display: "flex",
											alignItems: "center",
											width: 400,
										}}
									>
										<TextField
											sx={{
												p: "2px 4px",
												display: "flex",
												alignItems: "center",
												width: 400,
											}}
											type="text"
											value={formik.values.customerName}
											fullWidth
											label="Customer"
											InputProps={{
												readOnly: true,
											}}
											InputLabelProps={{
												shrink: formik.values?.customerId ? true : false,
											}}
											error={
												formik.touched.customerId &&
												Boolean(formik.errors.customerId)
											}
											helperText={
												formik.touched.customerId && formik.errors.customerId
											}
										/>
										<div>
											<IconButton
												type="button"
												sx={{ p: "10px" }}
												aria-label="add"
												onClick={() => {
													setSelectedModal("customer");
												}}
											>
												<SearchIcon />
											</IconButton>
										</div>
									</Paper>
								</Grid>

								<Grid xs={12} md={6}>
									<SelectComponent
										sx={{
											p: "2px 4px",
											display: "flex",
											alignItems: "center",
											width: 400,
										}}
										data={[
											{ value: "Cash", label: "Cash" },
											{ value: "Card", label: "Card" },
											{ value: "UPI", label: "UPI" },
										]}
										selected={formik.values.paymentMethod}
										label="Payment Mode"
										onChange={(value: string) => {
											formik.setFieldValue("paymentMethod", value);
										}}
									/>
								</Grid>
							</Grid>
						</Box>
						<OrderTable
							products={formik?.values?.products}
							services={formik?.values?.services}
						/>
					</CardContent>
				</Card>
			</form>
		</Dialog>
	);
}
