import { useState } from "react";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export const GENERATE_OTP = gql`
	mutation GenerateOPT($phone: String!) {
		generateOtp(phone: $phone)
	}
`;

export const useGenerateOtp = (): [boolean, (phone: string, callback: () => void) => Promise<void>]  => {
	const [data, setData] = useState(null);
	const [generateOtp, { loading }] = useMutation(GENERATE_OTP);

	const requestOtp = async(phone: string, callback: () => void) => {
		try {
			const { data } = await generateOtp({ variables: { phone: phone } });
			setData( data );
			if (data.generateOtp) {
				callback();
			} else {
				toast.error("Unable to generate OTP. Invalid number.")
			}
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(JSON.stringify(err))
			}
		}
	}

	return [loading, requestOtp]

}